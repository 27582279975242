import { setInstalledAt, setPromptEvent } from "../reducers/pwaReducer";
import { store } from "../index";

export class IosInstallPrompt implements InstallPrompt {
    prompt(): Promise<void> {
        return new Promise(resolve => {
            // Show the install dialog and listen for it to be closed

            // store.dispatch(setShowIosPrompt(true));
            // const unsubscribe = store.subscribe(() => {
            //     if (store.getState().pwa.showIosPrompt) {
            //         unsubscribe();
            //         resolve();
            //     }
            // })
        });
    }
}

export default function setupPWA() {
    const installedAt = localStorage.getItem("installedAt");
    if (installedAt !== null) {
        const installTime = new Date(installedAt);
        store.dispatch(setInstalledAt(installTime))
        console.log("PWA already installed. Time of installation is " + installTime);
    }

    // Register events
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);
    window.addEventListener('appinstalled', onAppInstalled);
}

function onBeforeInstallPrompt(event: BeforeInstallPromptEvent) {
    event.preventDefault();
    
    store.dispatch(setPromptEvent(event));
    console.log("beforeinstall: The browser just told us we can prompt the user for PWA installation");
}

function onAppInstalled(_: AppInstalledEvent) {
    const installDate = new Date();

    store.dispatch(setInstalledAt(installDate))
    localStorage.setItem("installedAt", installDate.toJSON());

    console.log("PWA just got installed");
}
