import { Reducer } from "redux";
import actionCreatorFactory, { isType } from "typescript-fsa";

/* ******* */
/* Actions */
const actionCreator = actionCreatorFactory("PWAActions");

export const setInstalledAt = actionCreator<Date>("SET_INSTALLED_AT");
export const setPromptEvent = actionCreator<BeforeInstallPromptEvent | undefined>("SET_PROMPT_EVENT");
// export const clearPromptEvent = actionCreator("CLEAR_CONTENT_TOKEN");
export const clearPromptEvent = setPromptEvent(undefined);


export interface PWAState {
    installed_at?: Date;
    promptEvent?: BeforeInstallPromptEvent;
}

export const pwaReducer: Reducer<PWAState> = (state = {}, action) => {
    if (isType(action, setInstalledAt)) {
        return {
            ...state,
            installed_at: action.payload,
            promptEvent: undefined,
        };
    } 
    
    if (isType(action, setPromptEvent)) {
        return {
            ...state,
            promptEvent: action.payload
        };
    }

    return state;
}
