import { Reducer } from "redux";
import actionCreatorFactory, { isType } from "typescript-fsa";

/* ******* */
/* Actions */
const actionCreator = actionCreatorFactory("NavigationActions");

export const setSubpageName = actionCreator<{ name: string }>("SET_SUPPAGE_NAME");
export const clearSubpageName = actionCreator("CLEAR_CONTENT_TOKEN");

/* ******* */
/* Reducer */

export interface NavigationState {
    subpageName?: string;
}
const initialState: NavigationState = {};

export const navigationReducer: Reducer<NavigationState> = (state = initialState, action) => {
    if (isType(action, setSubpageName)) {
        return {
            ...state,
            subpageName: action.payload.name,
        };
    }

    if (isType(action, clearSubpageName)) {
        return {
            ...state,
            subpageName: undefined,
        };
    }

    return state;
};
