import styled from "styled-components/macro";
import { Colors } from "../../common/colorScheme";
import { Sizes } from "../../common/sizeConstants";
import OverlayImage from "../../images/hf_logo.svg";

export const Container = styled.div<{ isSubPage: boolean; isVideoSubPage?: boolean }>`
    position: fixed;
    height: ${Sizes.headerBarHeight}px;
    width: 100%;
    z-index: 2;

    background: ${Colors.text};
    display: flex;
    flex-direction: column;
    background: ${({ isSubPage, isVideoSubPage }) => (isSubPage && !isVideoSubPage ? Colors.primaryGradient : Colors.whiteBackground)};
    color: ${({ isSubPage, isVideoSubPage }) => (isSubPage && !isVideoSubPage ? Colors.text : Colors.textGrey)};

    align-items: center;
    overflow: hidden;

    @media (min-width: ${Sizes.breakPointDesktop}) {
        background: ${Colors.whiteBackground};
    }
    @media (max-width: ${Sizes.breakPoint}) {
        ${({ isVideoSubPage }) => isVideoSubPage && "height: " + Sizes.headerBarHeightMobileVideo};
        z-index: 1;
    }

    @media print {
        display: none;
    }
`;

// Shadow Constants
const ROUNDING = 20;
const OFFSET = 7;

export const Shadow = styled.div<{ isVideoSubPage?: boolean }>`
    display: ${({ isVideoSubPage }) => (isVideoSubPage ? "none" : "block")};
    position: fixed;
    top: ${Sizes.headerBarHeight - ROUNDING}px;
    left: ${ROUNDING - OFFSET}px;
    width: calc(100% - ${(ROUNDING - OFFSET) * 2}px);
    height: ${ROUNDING}px;
    background-color: transparent;
    pointer-events: none;
    z-index: 2;

    html:not([data-scroll="0"]) & {
        box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25);
    }
`;

export const Title = styled.h1<{ isSubPage?: boolean }>`
    display: ${({ isSubPage }) => (isSubPage ? "none" : "block")};
    color: ${Colors.textGrey};
    font-size: 26px;
    font-weight: 400;
    margin: 14px 0;

    a {
        color: ${Colors.secondary};
        text-decoration: none;
    }

    @media (min-width: ${Sizes.breakPointDesktop}) {
        display: block;
        color: ${Colors.secondary};
    }
`;

export const PageName = styled.h1<{ isSubPage?: boolean }>`
    color: ${({ isSubPage }) => (isSubPage ? Colors.text : Colors.transparent)};
    font-size: 26px;
    font-weight: 400;
    margin-left: 20px;
`;

export const SubTitle = styled.h2<{ isDeviceItemSelected?: boolean }>`
    display: none;
    font-size: 14px;
    font-weight: 500;

    a {
        color: ${Colors.text};
        text-decoration: none;
    }

    @media (max-width: ${Sizes.breakPoint}) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: ${({ isDeviceItemSelected }) => (isDeviceItemSelected ? Colors.navTextActive : Colors.navTextInactive)};
    }

    @media (min-width: 1025px) {
        display: none;
    }
`;

export const HeaderOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    mask-image: url(${OverlayImage});
    mask-repeat: no-repeat;
    mask-size: 69%;
    mask-position: 156% 32%;
    background-color: #fff;
    opacity: 10%;
`;

export const BackButton = styled.div<{ isDeviceItemSelected?: boolean; isVideoPlayer?: boolean; notVisibleInMobile?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: start;
    position: inherit;
    min-height: ${({ isVideoPlayer }) => (isVideoPlayer ? "0px" : "70px")};
    z-index: 1;

    .backButton-container {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        margin-top: 0.7em;
        margin-bottom: 0.7em;
        height: 100%;
    }

    .backButton-button {
        position: absolute;
        top: 5px;
        left: 12px;

        border-radius: 50%;
        cursor: pointer;
        padding: 20px;

        @media (max-width: ${Sizes.breakPoint}) {
            padding: unset;
            margin-top: ${({ isVideoPlayer }) => (isVideoPlayer ? "-22.5px" : "0px")};
            top: ${({ isVideoPlayer }) => (isVideoPlayer ? "50%" : "8px")};
            ${({ isDeviceItemSelected }) =>
                isDeviceItemSelected ? `div > svg > path { fill: ${Colors.text}; }` : `div > svg > path { fill: ${Colors.navTextInactive}; }`};
            ${({ notVisibleInMobile }) => (notVisibleInMobile ? "display:none" : "")};
        }
    }
`;

export const TitleStyles = styled.div<{ isSubPage?: boolean }>`
    display: none; // Hide in "normal" view
    align-self: self-start;
    position: sticky;
    top: 0;
    bottom: 0;
    width: 100%;
    height: fit-content;
    z-index: 1;
    padding-bottom: 16px;

    @media (max-width: ${Sizes.breakPointDesktop}) {
        ${({ isSubPage }) => (isSubPage ? "display: inherit" : "display: none")};
        padding-bottom: calc(5px + ${Sizes.roundedCornerCompensation}px);
    }

    .titleStyles-title {
        z-index: 2;
        pointer-events: none;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: inherit;
`;
