import "core-js";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { App } from "./App";
import "./index.css";
import { AppState, reducer } from "./reducers/reducer";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import sagas from "./sagas/sagas";
import { favorite } from "./reducers/resourcesReducer";
import { Environment, getEnv } from "./common/env";
import { initDatadog } from "./common/config";
import setupPWA from "./common/pwa";

export let store: Store<AppState, any>;

function main() {
    const appEnv = getEnv();
    console.log(`Running in env: ${appEnv}`);

    initDatadog();

    // Make sure the webapp runs SSL
    const location = window.location.href + "";
    if (appEnv !== Environment.Local && location.startsWith("http://")) {
        window.location.href = location.replace("http://", "https://");
        // Stop executing the main function!
        return;
    }

    // We need to add remote logging from clients
    if (appEnv !== Environment.Local) {
        console.log = () => {}; // tslint:disable-line:no-empty
    }

    // Setup sagas
    const sagaMiddleware = createSagaMiddleware();

    // Only attach Redux devtools in dev mode
    let composeEnhancers = compose;
    if (appEnv === Environment.Local) {
        composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

    sagaMiddleware.run(sagas);

    if (localStorage.getItem("fav")) {
        const courseID = localStorage.getItem("fav") as string;
        store.dispatch(favorite(courseID));
    }

    // Call before first render so that we can dispatch data
    setupPWA();

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root") as HTMLElement
    );
}

main();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
