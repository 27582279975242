export const Colors = {
    primary: "#5FC797",
    primaryGradient: "linear-gradient(151.69deg, rgba(66, 180, 228, 0.2) 9.97%, rgba(255, 255, 255, 0) 53.14%), #5FC797",
    progressBarGradient: "linear-gradient(246.66deg, rgba(118, 216, 248, 0.2) 21.69%, rgba(255, 255, 255, 0) 78.15%), #5FC797;",
    progressBarContainer: "#3A474E",
    secondary: "#475A64",
    text: "#FFFFFF",
    textGrey: "#515151",
    sectionHeaderText: "#a0a0a0",
    border: "#ECECEC",
    background: "#F3F5F8",
    thumbnailBackground: "#fff",
    divider: "#cfd8dc",
    progressBar: "#587CDA",
    transparent: "rgb(0,0,0,0.0)",

    greySubtle: "#e2e4e7",
    headerBarBackground: "#FFFFFF",
    cardBackground: "#F3F5F8",
    cardTitle: "#474747",
    cardSubtext: "#3E3E3E",

    // Anim
    primaryHover: "#5aa284",
    primaryClick: "#74bd9e",

    whiteHover: "#f4f4f4",
    whiteClick: "#cecece",

    navTextActive: "#FFFFFF",
    navTextInactive: "#263238",
    navButtonActive: "#5FC797",
    navButtonInactive: "#F2F4F9",

    invertedNavBorderInactive: "#A5A5A5",

    sideBarWidth: 0,

    // Legacy
    positive: "#AAD282",
    neutral: "#F49C20",
    negative: "#E55354",
    favoriteInactive: "#d7d7d7",
    favoriteActive: "#ffa500",
    whiteBackground: "white",
};
