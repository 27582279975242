import { call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { addCourseIdSuccess, fetchResources, fetchResourcesError, fetchResourcesSuccess } from "../reducers/resourcesReducer";
import { fetchGet, preloadImages, unpackLanguage } from "../common/utils";
import { getOrganisation } from "../common/org";
import { Tracker } from "../analytics/tracking";

const LANG = "da";

function* fetchResourcesSaga(_: Action<void>) {
    try {
        let translations: any;
        let pack: any;

        try {
            pack = yield call(fetchGet, `content/organization/${getOrganisation()}`);
            translations = unpackLanguage(pack.programs, LANG, ["deviceInfo"]);

            if (pack.error) {
                return yield put(fetchResourcesError());
            }

            // TODO: Fix language selection here.
            const language = 0;

            const hasLogo = pack?.logo && typeof pack?.logo !== "string";
            const logo = hasLogo && pack?.logo?.translations[language].src;

            preloadImages(translations[language]);
            yield put(fetchResourcesSuccess({ slug: pack.slug, logo, course: translations }));

            //Update analytics
            Tracker.updateAnalyticsDetails({ organization: pack.slug, department: pack.department });
        } catch (e: any) {
            console.log("Fetch failed because we dont have a token", e);
            throw new Error("Couldn't load content pack");
        }
    } catch (e: any) {
        console.warn(`Something when wrong in the saga: ${JSON.stringify({ e, msg: e.message })}`, e.message);
        yield put(fetchResourcesError());
    }
}

function* courseUpdatedSaga(courseChangedAction: Action<string>) {
    //Update analytics
    yield Tracker.updateAnalyticsDetails({ program: courseChangedAction.payload });
}

export function* resourcesSaga() {
    yield takeEvery(fetchResources, fetchResourcesSaga);
    yield takeEvery(addCourseIdSuccess, courseUpdatedSaga);
}
