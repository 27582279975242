import styled, { css } from "styled-components/macro";
import { Colors } from "../common/colorScheme";
import { Sizes } from "../common/sizeConstants";
import { Icons } from "../images/icons";

export const FlexWrapper = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background: ${Colors.primaryGradient};

    @media print {
        display: block;
    }
`;

export const CenterContent = styled.div`
    margin-top: 39px;
    max-width: 500px;
    align-items: flex-start;
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: start;
    flex-direction: column;
`;

export const ProcedureTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-bottom: 16px;
`;

export const ContentContainer = styled.div`
    top: ${Sizes.headerBarHeight}px;
    background: white;
    align-items: center;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const HeaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    background: ${Colors.primaryGradient};
    color: ${Colors.text};
    display: flex;
    min-height: ${Sizes.headerBarHeight}px;
    max-height: ${Sizes.headerBarHeight}px;
    align-items: center;
    justify-content: center;

    @media print {
        display: none;
    }
`;

export const BackgroundLogo = css`
    background-image: url(${Icons.Logo});
    background-repeat: no-repeat;
    background-position: 10% 45%;
    background-size: 50%;
`;

export const HeaderOverlay = styled.div`
    position: absolute;
    z-index: -1;
    top: 0;
    right: -45%;
    transform: translate(0%, -15%);
    -ms-transform: translate(0%, -15%);
    width: 100%;
    height: 143%;
    opacity: 8%;
    ${BackgroundLogo}
`;

export const TitleContent = styled.div`
    display: flex;
    margin-left: 32px;
    padding: 0 0 29px;
`;
export const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 18px 28px;
    margin-top: 36px;
    max-width: 343px;
`;

export const RoundedCorners = styled.div`
    // Around rounded corners
    position: fixed;
    background: ${Colors.primaryGradient};
    height: 20px;
    width: 100%;
    top: ${Sizes.headerBarHeight}px;
    z-index: 0;

    // Between rounded corners (content)
    .between-rounded-corners {
        border-radius: 20px 20px 0 0;
        background: white;
        height: 100%;
        width: 100%;
    }
`;

export const TitleSplits = styled.span`
    margin: -3px 0;
`;

export const Title = styled.h1`
    display: flex;
    flex-direction: column;
    color: ${Colors.text};
    font-size: 26px;
    padding-left: 14px;
    margin: 0;

    a {
        color: ${Colors.text};
        text-decoration: none;
    }
`;
