import { Reducer } from "redux";
import actionCreatorFactory, { isType } from "typescript-fsa";

/* Actions */
const actionCreator = actionCreatorFactory("ResourcesAction");

export const updateContentToken = actionCreator<{ token: string }>("UPDATE_CONTENT_TOKEN");

export const fetchResources = actionCreator("FETCH_RESOURCES");
export const fetchResourcesSuccess = actionCreator<{slug: string, logo?: string, course: Course[]}>("FETCH_RESOURCES_SUCESS");
export const fetchResourcesError = actionCreator("FETCH_RESOURCES_ERRROR");
export const addCourseIdSuccess = actionCreator<string>("ADD_COURSE_ID_SUCCESS");
export const favorite = actionCreator<string>("FAVORITE");
/* ***** */
/* Types */

export interface TextElement {
    type: "textElement";
    text: string;
    header: string;
}

export interface Organization {
    type: "Organization";
    slug: string;
    logo?: string;
}

export interface RichText {
    type: "Text";
    name: string;
    content: any;
}

export interface VideoFile {
    type: "Video";
    _id: string;
    name: string;
    contentType: string;
    src: string;
    thumbnailSrc?: string;
    length: number;
}

export interface ImageFile {
    type: "Image";
    _id: string;
    name: string;
    contentType: string;
    src: string;
}

export interface SectionHeader {
    type: "sectionHeader";
    name: string;
}

export interface VideoListItem {
    name: string;
    video: VideoFile;
}
export interface VideoList {
    type: "videoList";
    name: string;
    sections: Array<{
        sectionName: string;
        videoList: VideoListItem[];
    }>;
}

export interface CallToAction {
    type: "callToAction";
    header: string;
    expandText: string;
    content: RichText;
}

export interface StepGuide {
    type: "stepGuide";
    header: string;
    steps: Array<{
        text: string;
        url?: string;
        image?: ImageFile;
        video?: VideoFile;
    }>;
}

export interface DeviceInfo {
    type: "deviceInfo";
    _id: string;
    description: string;
    header: string;
    image: ImageFile;
    content: Array<SectionHeader | TextElement | StepGuide | VideoList>;
}

export interface Course {
    type: "citizenCoursePack";
    header: string;
    organization?: Organization;
    courseId: string;
    icon: any;
    description: string;
    videoTab: Array<SectionHeader | VideoList | CallToAction>;
    deviceTab: DeviceInfo[];
}

/* *********** */
/* Type guards */

export type Content = TextElement | StepGuide | SectionHeader | VideoList | CallToAction | DeviceInfo;
export function isTextElement(content: Content): content is TextElement {
    return content.type === "textElement";
}
export function isStepGuide(content: Content): content is StepGuide {
    return content.type === "stepGuide";
}
export function isSectionHeader(content: Content): content is SectionHeader {
    return content.type === "sectionHeader";
}
export function isVideoList(content: Content): content is VideoList {
    return content.type === "videoList";
}
export function isCallToAction(content: Content): content is CallToAction {
    return content.type === "callToAction";
}
export function isDeviceInfo(content: Content): content is DeviceInfo {
    return content.type === "deviceInfo";
}

/* ******* */
/* Reducer */

export interface ResourceState {
    isLoading: boolean;
    contentToken?: string;
    courses?: Course[];
    slug?: string;
    logo?: string;
    error: boolean;
    courseActive?: string;
    favorites?: string;
}


const initialState: ResourceState = {
    isLoading: true,
    courseActive: "",
    favorites: "",
    slug: "",
    error: false,
};

export const resourcesReducer: Reducer<ResourceState> = (state = initialState, action) => {
    if (isType(action, updateContentToken)) {
        return {
            ...state,
            contentToken: action.payload.token,
        };
    }

    if (isType(action, fetchResources)) {
        return {
            ...state,
            isLoading: true,
            courses: undefined,
        };
    }

    if (isType(action, fetchResourcesError)) {
        return {
            ...state,
            isLoading: false,
            error: true
        };
    }

    if (isType(action, fetchResourcesSuccess)) {
        return {
            ...state,
            isLoading: false,
            error: false,
            courses: action.payload.course,
            slug: action.payload.slug,
            logo: action.payload.logo,
        };
    }

    if (isType(action, addCourseIdSuccess)) {
        return {
            ...state,
            courseActive: action.payload,
        };
    }

    if (isType(action, favorite)) {
        return {
            ...state,
            favorites: action.payload,
        };
    }

    return state;
};
