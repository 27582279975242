import * as React from "react";
import {ReactComponent as HjemmeForlobLogo} from "../images/hf_logo.svg";
import SvgIcon from "../images/svgIcon";
import * as Styles from "./OverviewScreen.styles";
import * as StylesHeader from "./navigation/HeaderBar.styles";

export function OverviewScreen() {
      return (
          <Styles.FlexWrapper>
              <Styles.HeaderContainer>
                  <Styles.HeaderOverlay />
                  <Styles.HeaderContent>
                      <Styles.TitleContent>
                          <SvgIcon Icon={HjemmeForlobLogo} width={42} />
                          <Styles.Title>
                              <Styles.TitleSplits>Mit</Styles.TitleSplits>
                              <Styles.TitleSplits>Hjemmeforløb</Styles.TitleSplits>
                          </Styles.Title>
                      </Styles.TitleContent>
                  </Styles.HeaderContent>
                  <StylesHeader.Shadow />
              </Styles.HeaderContainer>
              <Styles.ContentContainer>
                  <Styles.RoundedCorners>
                      <div className="between-rounded-corners" />
                  </Styles.RoundedCorners>
                  <Styles.CenterContent>
                    <Styles.ProcedureTitle>
                      <div>Indholdet er flyttet.</div>
                      <div><a target="_blank" rel="noreferrer" href="https://mitforlob.dk/auth/vpptjnjz">klik her</a></div>
                    </Styles.ProcedureTitle>
                  </Styles.CenterContent>
              </Styles.ContentContainer>
          </Styles.FlexWrapper>
      );
}
