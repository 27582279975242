import * as React from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { AppState } from "./reducers/reducer";
import { OverviewScreen } from "./screens/OverviewScreen";

/*
 * View pager example using Framer Motion
 *     https://www.framer.com/motion/
 *     https://codesandbox.io/s/motion-page-ts-brz9n
 */

interface IPropsFromDispatch {
    fetchData: (source: string) => void;
}

type IProps = IPropsFromDispatch;

class CApp extends React.Component<IProps> {
    componentDidMount() {
        this.props.fetchData("mount");
    }
    render() {
        return (
            <BrowserRouter>
                <Route path="*" component={OverviewScreen} exact={true} />
            </BrowserRouter>
        );
    }
}

const mapStateToProps: MapStateToProps<{}, {}, AppState> = () => {
    return {};
};

const mapDispatchToProps: MapDispatchToProps<IPropsFromDispatch, {}> = (dispatch) => {
    return {
        fetchData: () => {
            //dispatch(fetchResources());
        },
    };
};
export const App = connect(mapStateToProps, mapDispatchToProps)(CApp);
