import {Environment, getEnv} from "./env";

let organisation;
export function getOrganisation() {
    if (!organisation) {
        extractOrganisation(getEnv(), window.location.hostname, window.location.search);
    }
    return organisation;
}

export function extractOrganisation(env: Environment, hostname: string, search: string) {
    const domain = hostname.replace('www.', '').split('.');
    const parameters = new URLSearchParams(search);

    if (env === Environment.Prod) {
        organisation = domain[0];
    } else if (parameters.get('org')) {
        organisation = parameters.get('org');
    } else if (domain[0] === 'qa') {
        organisation = domain[1];
    } else {
        organisation = 'meow';
    }
    console.log('Organisation: ', organisation);

    return organisation;
}