export const Sizes = {
    breakPoint: "1025px",
    breakPointMid: "590px",
    headerBarHeight: 220,
    navigationBarHeight: 32,
    navigationBarBorder: 5,
    roundedCornerCompensation: 20,
    get breakPointDesktop() {
        return this.breakPoint;
    },
    get headerBarHeightMobile() {
        return 228 + this.roundedCornerCompensation + "px";
    },
    get headerBarHeightMobileVideo() {
        return 42 + this.roundedCornerCompensation + "px";
    },
};
