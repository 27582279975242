import { combineReducers } from "redux";
import { ResourceState, resourcesReducer } from "./resourcesReducer";
import { navigationReducer, NavigationState } from "./navReducer";
import { pwaReducer, PWAState } from "./pwaReducer";

export interface AppState {
    resources: ResourceState;
    navigation: NavigationState;
    pwa: PWAState;
}

export const reducer = combineReducers({
    resources: resourcesReducer,
    navigation: navigationReducer,
    pwa: pwaReducer,
});
