import { Environment, getEnv } from "./env";
import { datadogRum } from "@datadog/browser-rum";

export const VCORE_API_KEY = "citizen_education.Qhkq/HYCbNN4ClsY53Dd3EJwS/febYX9SNy3alDJBO4/SUyok22Jd9ftH1fMO67f";

export const version = process.env.REACT_APP_VERSION || "Unknown version";
export const gitBranch = process.env.REACT_APP_GIT_BRANCH || "Unknown";
export const gitCommit = process.env.REACT_APP_GIT_HASH || "Unknown";

console.log("Config info", {
    version,
    gitBranch,
    gitCommit,
});

// const { hostname } = window.location;

const backends: Record<string, string> = {
    // Local: hostname + ":3010",
    Local: "https://api.core.visikon.com",
    QA: "https://api.core.visikon.com",
    Prod: "https://api.core.visikon.com",
};

const blobs: Record<string, string> = {
    // Local: "https://visikoncorestorageqa.blob.core.windows.net/blobs-qa/", // was same also for QA
    Local: "https://visikoncorestorage.blob.core.windows.net/blobs-production/",
    QA: "https://visikoncorestorage.blob.core.windows.net/blobs-production/",
    Prod: "https://visikoncorestorage.blob.core.windows.net/blobs-production/",
};

function baseUrl() {
    const env = getEnv();

    /*    const { protocol } = window.location;

    console.log("backend", `${protocol}://${backends[env]}/v1/`);
    return `${protocol}//${backends[env]}/v1/`; */

    console.log("backend", `${backends[env]}/v1/`);
    return `${backends[env]}/v1/`;
}

function baseBlobUrl() {
    const env = getEnv();

    console.log("blobs", blobs[env]);
    return blobs[env];
}

function MOCKgetMediaVariation() {
    return "5b91713d9bfc750039a336a5";
}

export const API = {
    apiKey: VCORE_API_KEY,
    baseURL: baseUrl(),
    baseBlobUrl: baseBlobUrl(),
    MOCKmediaVariation: MOCKgetMediaVariation(),
};

export function initDatadog() {
    const env = getEnv();

    if (env === Environment.Local) return;

    datadogRum.init({
        applicationId: "30a37833-ee5a-4df6-a395-68a9fa872f54",
        clientToken: "pubc49286aa0d294bff9393605bf2d89343",
        site: "us3.datadoghq.com",
        service: "citizenedu-web",
        env: env,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
    });
}

export function PwaInstallsEnabled() {
    const envFlag = process.env.REACT_APP_PROMPT_PWA_INSTALL;

    return envFlag ? envFlag === "true" : getEnv() !== Environment.Prod;
}
