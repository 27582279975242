import ReactPiwik from "react-piwik";
import {Environment, getEnv} from "../common/env";

const {version} = require("../../package.json");

const IS_DISABLED = getEnv() === Environment.Local;
// const IS_DISABLED = false;
const PROD_MATOMO_SITE_ID = 8;
const DEMO_MATOMO_SITE_ID = 9;

const siteId =
    getEnv() === Environment.Prod ? PROD_MATOMO_SITE_ID :
        getEnv() === Environment.QA ? DEMO_MATOMO_SITE_ID :
            parseInt(process.env.REACT_APP_TRACKING_ID ?? "", 10);
console.log(`Using matomo site ID ${siteId} `);

new ReactPiwik({
    url: "https://matomo.visikon.com",
    siteId,
    trackDocumentTitle: false,
});

enum PiwikCmd {
    trackEvent = "trackEvent",
    setDocumentTitle = "setDocumentTitle",
    trackPageView = "trackPageView",
    setCustomDimension = "setCustomDimension",
    enableHeartBeatTimer = "enableHeartBeatTimer",
    setLinkClasses = "setLinkClasses",
    setCustomUrl = "setCustomUrl"
}
function push(...args: any) {
    ReactPiwik.push(args)
}

export enum Category {
    Courses = "Courses",
    CallToAction = "CallToAction",
    Hooks = "Hooks",
    Device = "Device",
    Favorite = "Favorite",
    DeviceDetail = "DeviceDetail",
    Video = "Video",
};

push(PiwikCmd.enableHeartBeatTimer)
push(PiwikCmd.setLinkClasses, "external")

export interface AnalyticsDetails {
    program?: string;
    department?: string;
    organization?: string;
}

type PartialAnalyticsDetails = Partial<AnalyticsDetails>;

export class Tracker {
    private static details: AnalyticsDetails | undefined;

    public static trackEvent(category: Category, action: string, name?: string, value?: string) {
        console.log("Track event:", category, action, name);
        if (IS_DISABLED) {
            return;
        }
        push(PiwikCmd.trackEvent, category, action, name, value)
    }

    public static trackScreen(title: string) {
        console.log("Track screen: ", title);
        if (IS_DISABLED) {
            return;
        }
        push(PiwikCmd.setDocumentTitle, title);
        push(PiwikCmd.trackPageView);
    }

    public static updateAnalyticsDetails(details: PartialAnalyticsDetails) {
        console.log("Update analytics ", details);
        if (IS_DISABLED) {
            return;
        }

        this.details = {...this.details, ...details};

        const { program, department, organization} = this.details;

        // Always present
        push(PiwikCmd.setCustomDimension, 1, version);
        push(PiwikCmd.setCustomDimension, 5, "web");

        // Additional
        if (program) push(PiwikCmd.setCustomDimension, 2, program);
        if (department) push(PiwikCmd.setCustomDimension, 3, department);
        if (organization) push(PiwikCmd.setCustomDimension, 4, organization);
    }
}
